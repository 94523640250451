<template>
  <section id="transfer-information">
    <bg-grid>
      <bg-grid-item :col="12">
        <bg-card>
          <div class="mb-16">
            <bg-skeleton v-if="loading" width="150px" height="25px" />
            <bg-text v-else size="heading-5">
              Transfer Pendapatan {{ currentMonth }}
            </bg-text>
          </div>

          <bg-divider />

          <bg-grid v-if="loading" class="mt-24">
            <bg-grid-item v-for="i in 3" :cols="4" :key="i">
              <bg-skeleton width="60%" height="20px" />
            </bg-grid-item>
          </bg-grid>

          <template v-else>
            <bg-grid class="mt-24">
              <bg-grid-item :col="4">
                <bg-list-item title="Status Data Pendapatan">
                  Data belum tersedia
                </bg-list-item>
              </bg-grid-item>

              <bg-grid-item :col="4">
                <bg-list-item title="Status Transfer">
                  Data belum tersedia
                </bg-list-item>
              </bg-grid-item>

              <bg-grid-item :col="4">
                <bg-list-item title="Model Kerja Sama">
                  {{ disbursementType }}
                </bg-list-item>
              </bg-grid-item>
            </bg-grid>

            <bg-grid>
              <bg-grid-item :col="4" class="mb-0">
                <bg-list-item title="Jadwal Transfer">
                  {{ disbursement.transfer_date | date }}
                </bg-list-item>
              </bg-grid-item>

              <bg-grid-item :col="4" class="mb-0">
                <bg-list-item title="Cut Off">
                  {{ cutOffDate }}
                </bg-list-item>
              </bg-grid-item>

              <bg-grid-item :col="4" class="mb-0">
                <bg-list-item title="Total Transfer Pendapatan">
                  Data belum tersedia
                </bg-list-item>
              </bg-grid-item>
            </bg-grid>

            <bg-divider class="mb-32 mt-32" dashed />

            <bg-grid>
              <bg-grid-item :col="12">
                <div class="toggle-item mb-24">
                  <div class="copytext">
                    <bg-text class="mb-4">Transfer Pendapatan Otomatis</bg-text>
                    <bg-text size="body-2"
                      >Mengaktifkan Transfer Pendapatan Otomatis akan membuat
                      transfer pendapatan pada properti ini otomatis
                      dikonfirmasi dan ditransfer setiap bulannya.</bg-text
                    >
                  </div>
                  <bg-switch
                    v-model="localSwitches.autoDisburse"
                    @input="toggleModal('autoDisburse')"
                    data-testid="autoDisburse-switch"
                  />
                </div>

                <div class="toggle-item">
                  <div class="copytext">
                    <bg-text class="mb-4">
                      PDF Laporan Pendapatan & Bukti Transfer
                    </bg-text>
                    <bg-text size="body-2"
                      >Jika diaktifkan, pemilik akan menerima notifikasi via
                      WhatsApp berupa informasi transfer pendapatan, PDF laporan
                      pendapatan, dan bukti transfer.</bg-text
                    >
                  </div>
                  <bg-switch
                    v-model="localSwitches.pdfReport"
                    @input="toggleModal('pdfReport')"
                    data-testid="pdfReport-switch"
                  />
                </div>
              </bg-grid-item>
            </bg-grid>

            <bg-divider class="mb-32 mt-32" dashed />

            <bg-grid>
              <bg-grid-item :col="12" class="mb-8">
                <bg-text size="heading-6" id="transfer-pendapatan-history">
                  Riwayat Transfer Pendapatan
                </bg-text>
              </bg-grid-item>
              <bg-grid-item :col="12" class="mb-0">
                <table class="ss-table">
                  <thead>
                    <tr>
                      <th role="columnheader" class="ta-l">
                        <bg-text size="title-5">Periode</bg-text>
                      </th>
                      <th role="columnheader" class="ta-l">
                        <bg-text size="title-5">ID</bg-text>
                      </th>
                      <th role="columnheader" class="ta-l">
                        <bg-text size="title-5">
                          Total Transfer Pendapatan
                        </bg-text>
                      </th>
                      <th role="columnheader" class="ta-l">
                        <bg-text size="title-5">Status Transfer</bg-text>
                      </th>
                      <th role="columnheader" class="ta-c">
                        <bg-text size="title-5">Action</bg-text>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(history, i) in financialHistories"
                      :key="`history-${i}`"
                    >
                      <td>{{ history.transfer_date | date('MMMM YYYY') }}</td>
                      <td>{{ history.approval_id || '-' }}</td>
                      <td>
                        {{ history.total_transfer | rupiah }}
                      </td>
                      <td>
                        <bg-label-rainbow
                          :color="
                            transferStatusLabelColor[history.status_transfer]
                          "
                        >
                          <span class="tt-capitalize">
                            {{ history.status_transfer | transferStatusText }}
                          </span>
                        </bg-label-rainbow>
                        <span
                          v-if="
                            history.status_transfer === 'transferred' ||
                            history.status_transfer === 'auto_transferred'
                        ">
                          at
                          {{
                            history.transfer_date | date('DD MMMM YYYY, HH:mm')
                          }}
                        </span>
                      </td>
                      <td>
                        <table-action-menu>
                          <bg-list-item
                            v-if="history.approval_id"
                            clickable
                            @click="goToInvoiceList(history.approval_id)"
                          >
                            Lihat Detail
                          </bg-list-item>
                          <bg-list-item
                            v-if="isShowTransferDownloadButton(history)"
                            clickable
                            @click="downloadNow(history.receipt)"
                          >
                            Unduh Bukti Transfer
                          </bg-list-item>
                          <bg-list-item
                            v-if="isShowReportDownloadButton(history)"
                            clickable
                            @click="getReportPdf(history)"
                          >
                            Unduh Laporan
                          </bg-list-item>
                        </table-action-menu>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </bg-grid-item>
            </bg-grid>
          </template>
        </bg-card>
      </bg-grid-item>
    </bg-grid>

    <bg-modal
      v-model="isShowModal.loadingDownloadReport"
      :close-on-click-backdrop="false"
      desktop-size="sm"
      :closable="false"
    >
      <div class="flex align-center justify-center loader-wrapper">
        <div class="spin-loader mb-8"></div>
        <p>Sedang mengunduh...</p>
      </div>
    </bg-modal>

    <bg-modal
      v-model="isShowModal.autoDisburse"
      :close-on-click-backdrop="false"
      desktop-size="sm"
      :closable="false"
      :title="autoDisburseModalText.title"
      :description="autoDisburseModalText.description"
      button-main-text="Ya"
      button-second-text="Tidak"
      @click-main-action="confirmToggle('autoDisburse')"
      @click-second-action="cancelToggle('autoDisburse')"
      :button-main-props="ModalButtonProps.main"
      :button-second-props="ModalButtonProps.second"
    />

    <bg-modal
      v-model="isShowModal.pdfReport"
      :close-on-click-backdrop="false"
      desktop-size="sm"
      :closable="false"
      :title="pdfReportModalText.title"
      :description="pdfReportModalText.description"
      button-main-text="Ya"
      button-second-text="Tidak"
      @click-main-action="confirmToggle('pdfReport')"
      @click-second-action="cancelToggle('pdfReport')"
      :button-main-props="ModalButtonProps.main"
      :button-second-props="ModalButtonProps.second"
    />

    <bg-modal
      v-model="isShowModal.noDisburseToggle"
      desktop-size="sm"
      :closable="false"
      title="Pengubahan Tidak Dapat Dilakukan"
      description="Untuk saat ini, Anda tidak dapat mengubah pengaturan Transfer Pendapatan Otomatis. Silakan coba kembali dalam 1x24 jam mendatang."
      button-second-text="Kembali"
      @click-second-action="cancelToggle('autoDisburse')"
    />
  </section>
</template>

<script>
import {
  BgGrid,
  BgGridItem,
  BgCard,
  BgText,
  BgDivider,
  BgListItem,
  BgSkeleton,
  BgLabelRainbow,
  BgModal,
  BgSwitch,
} from 'bangul-vue';
import TableActionMenu from '@admin_molecules/TableActionMenu';
import {
  dateFormatterToDisplay as format,
  dayjs,
  rupiahFormatter,
} from 'Utils/formatter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import { encrypt } from 'Utils/etc';
import invoiceApi from '@admin/api/endpoints/detail-transfer';
import detailTransferApi from '@admin_endpoints/detail-transfer';
import disbursementApi from '@admin_endpoints/disbursement-approval';
import { capitalize } from 'Utils/typography';
import { TYPE_TRANSFORM } from './constants';

dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);

export default {
  name: 'TransferInformation',

  components: {
    BgText,
    BgGrid,
    BgGridItem,
    BgCard,
    BgDivider,
    BgListItem,
    BgSkeleton,
    BgLabelRainbow,
    TableActionMenu,
    BgModal,
    BgSwitch,
  },

  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    disbursement: {
      type: Object,
      default: () => ({}),
    },
    financialHistories: {
      type: Array,
      default: () => [],
    },
    switches: {
      type: Object,
      default: () => ({}),
    },
  },

  filters: {
    date: (value, formatDate) => (value ? format(value, formatDate) : '-'),
    rupiah: value => {
      let nominal = value === '-' || value === null ? 0 : value;
      nominal = String(nominal).replace(/\..+/, '');
      return rupiahFormatter(nominal);
    },
    transferStatusText: value => {
      if (value) {
        return value.replace(/_/g, ' ');
      }

      return '-';
    },
  },

  data() {
    return {
      currentMonth: format(new Date(), 'MMMM YYYY'),
      transferStatusLabelColor: {
        waiting: 'yellow',
        processing: 'white',
        failed: 'red',
        transferred: 'green',
        auto_transferred: 'green'
      },
      transferInfo: {},
      invoices: [],
      costList: [],
      revenueSection: {},
      isShowModal: {
        loadingDownloadReport: false,
        autoDisburse: false,
        pdfReport: false,
        noDisburseToggle: false,
      },
      ModalButtonProps: {
        main: {
          loading: false,
        },
        second: {
          disabled: false,
        },
      },
      autoDisburseModalText: {
          title: '',
          description: ''
      },

      pdfReportModalText: {
          title: '',
          description: ''
      },
    };
  },

  computed: {
    cutOffDate() {
      const cutOffStart = this.disbursement.cutoff_period_start;
      const cutOffEnd = this.disbursement.cutoff_period_end;

      if (cutOffStart && cutOffEnd) {
        const start = dayjs(cutOffStart);
        const end = dayjs(cutOffEnd);
        const isDifferentYear = !start.isSame(end, 'year');
        const startDateFormat = isDifferentYear ? 'DD MMMM YYYY' : 'DD MMMM';

        return `${format(start, startDateFormat)} - ${format(
          end,
          'DD MMMM YYYY'
        )}`;
      }

      return '-';
    },

    disbursementType() {
      const types = this.disbursement.type;

      if (!types || !types.length) return '-';

      const transform = type =>
        TYPE_TRANSFORM[type] || capitalize(type.replace(/_/g, ' '));

      return types.map(transform).join(', ');
    },

    isShowTransferDownloadButton() {
      return historyData =>
        (historyData.status_transfer === 'transferred' || historyData.status_transfer === 'auto_transferred') && !!historyData.receipt;
    },

    isShowReportDownloadButton() {
      return historyData =>
        (historyData.status_transfer === 'transferred' || historyData.status_transfer === 'auto_transferred') &&
        (historyData.approval_id || historyData.report);
    },

    htmlToPdfOptions() {
      return {
        margin: 6.5,
        filename: `Laporan Pendapatan_${this.transferInfo.property_name}_${this.currentTime}`,
        image: {
          type: 'png',
        },
        jsPDF: {
          format: 'a4',
          orientation: 'portrait',
        },
        pagebreak: {
          mode: ['css'],
          avoid: ['tr', '.split'],
        },
      };
    },

    currentTime() {
      return format(new Date(), 'YYYY-MM-DDTHHmmss');
    },

    localSwitches() {
      return this.switches;
    },

    isAutoDisburseToggleable() {
      const disabledDate = dayjs(this.disbursement.cutoff_period_end).add(1, 'day');
      const currentTime = dayjs();

      const startOfDisabledTime = disabledDate.startOf('day');
      const endOfDisabledTime = disabledDate
        .set('hour', 7)
        .set('minute', 30)
        .set('second', 0);

      const isBetween =
        currentTime.isSameOrAfter(startOfDisabledTime) &&
        currentTime.isSameOrBefore(endOfDisabledTime);

      return isBetween;
    },
  },

  methods: {
    goToInvoiceList(approvalId) {
      if (!approvalId) return;

      this.$router.push({
        name: 'disbursement.detail-transfer',
        params: {
          approval_id: approvalId,
        },
      });
    },

    downloadNow(downloadLink) {
      if (!downloadLink) return;

      window.open(downloadLink);
      window.opener = null;
    },

    getReportPdf(historyData) {
      const year = dayjs(historyData.transfer_data).year();
      const month = dayjs(historyData.transfer_data).month();
      const encrypted = encrypt(
        `${historyData.disbursement_id}`,
        process.env.VUE_APP_OWNER_CP_DISBURSEMENT_REPORT_SECRET_IV,
        process.env.VUE_APP_OWNER_CP_DISBURSEMENT_REPORT_SECRET_KEY
      );

      const url = `${
        process.env.VUE_APP_API_URL
      }/laporan-pendapatan/${year}/${month}/${btoa(encrypted)}.pdf`;

      window.open(url, '_blank');
    },

    async generateReport(approvalId) {
      this.isShowModal.loadingDownloadReport = true;

      try {
        const [disbursement, invoices, costs, revenue] = await Promise.all([
          invoiceApi.getDisbursementDetail(approvalId),
          detailTransferApi.getInvoiceList(approvalId),
          invoiceApi.getOperationalCost({ approval_id: approvalId }),
          disbursementApi.getDisbursementAmountProperties(approvalId),
        ]);

        this.transferInfo = disbursement.data.data;
        this.invoices = invoices.data.data;
        this.costList = costs.data.data;
        this.revenueSection = revenue.data.data;

        await this.$nextTick();
        this.$refs.html2Pdf.generatePdf();
      } catch (error) {
        console.error(error);
        this.isShowModal.loadingDownloadReport = false;
        this.$toast.show('Terjadi kesalahan saat mengunduh laporan.');
      }
    },

    pdfGenerated() {
      this.isShowModal.loadingDownloadReport = false;
      this.$toast.show('Laporan pendapatan berhasil diunduh');
    },

    toggleModal(modalName) {
      const title = {
        autoDisburse: this.switches.autoDisburse
          ? 'Yakin ingin mengaktifkan Transfer Pendapatan Otomatis?'
          : 'Yakin ingin menonaktifkan Transfer Pendapatan Otomatis?',
        pdfReport: 'Yakin ingin ubah pengaturan kirim PDF laporan pendapatan?',
      };

      const description = {
        autoDisburse: this.switches.autoDisburse
          ? 'Transfer pendapatan pada properti ini akan dikonfirmasi dan ditransfer secara otomatis oleh sistem setiap bulannya.'
          : 'Jika dinonaktifkan, transfer pendapatan pada properti ini harus dikonfirmasi secara manual setiap bulannya.',
        pdfReport: this.switches.pdfReport
          ? 'Pemilik akan menerima notifikasi transfer yang lengkap dengan dokumen PDF laporan pendapatan dan bukti transfer setiap bulannya.'
          : 'Pemilik hanya akan menerima notifikasi transfer. Dokumen PDF laporan pendapatan dan bukti transfer bisa dilihat di Dashboard Singgahsini pemilik.',
      };

      this[`${modalName}ModalText`].title = title[modalName];
      this[`${modalName}ModalText`].description = description[modalName];

      if (modalName === 'autoDisburse' && this.isAutoDisburseToggleable) {
        this.isShowModal.noDisburseToggle = !this.isShowModal.noDisburseToggle;
      } else {
        this.isShowModal[modalName] = !this.isShowModal[modalName];
      }
    },

    cancelToggle(modalName) {
      this.toggleModal(modalName);
      this.$emit('cancel-toggle', modalName);
    },

    async confirmToggle(modalName) {
      const toastText = {
        autoDisburse: `Transfer Pendapatan Otomatis ${
          this.switches.autoDisburse ? 'diaktifkan' : 'dinonaktifkan'
        }`,
        pdfReport: 'Pengaturan kirim laporan pendapatan telah diubah',
      };

      this.ModalButtonProps = {
        main: {
          loading: true,
        },
        second: {
          disabled: true,
        },
      };

      const finishToggle = success => {
        if (success) {
          this.toggleModal(modalName);
          this.$toast.show(toastText[modalName]);
        }

        this.ModalButtonProps = {
          main: {
            loading: false,
          },
          second: {
            disabled: false,
          },
        };
      };

      if (modalName === 'autoDisburse') {
        await this.$emit(
          'fetch-post-auto-disburse-toggle',
          this.switches.autoDisburse,
          payload => {
            finishToggle(payload);
          }
        );
      }

      if (modalName === 'pdfReport') {
        await this.$emit(
          'fetch-post-pdf-report-toggle',
          this.switches.pdfReport,
          payload => {
            finishToggle(payload);
          }
        );
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.loader-wrapper {
  flex-direction: column;
  padding: 30px 0;
}
.spin-loader {
  width: 30px;
  height: 30px;
  border: 3px solid #404040;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

.toggle-item {
  max-width: 400px;
  width: 100%;
  display: flex;
  .copytext {
    flex: 0 1 auto;
    padding-right: 8px;
  }

  .bg-c-switch {
    flex: 0 0 40px;
  }
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
